import React from 'react';

// page specific configuration
import provenanceJSON from './provenance.json';


const Provenance = (props) => {
  return (
    <pre style={{ 'text-align': 'left' }}>
      { JSON.stringify(provenanceJSON, null, 2) }
    </pre>
  );
}


export default Provenance;
