import EthereumUtils from "../utils/Ethereum.js"


class Contract {
  static async getContract(web3, abi, address) {
    return await new web3.eth.Contract(abi, address)
  }


  static async getContractName(contract) {
    return await contract.methods.TOKEN_NAME().call();
  }


  static async getTokenPrice(web3, contract, denomination) {
    const tokenPrice = await contract.methods.TOKEN_PRICE().call();

    return web3.utils.fromWei(tokenPrice, denomination);
  }


  static async getTokenSupply(contract) {
    return await contract.methods.totalSupply().call();
  }


  static async getBalance(web3, address, denomination) {
    const balance = await new web3.eth.getBalance(address);

    return web3.utils.fromWei(balance, denomination);
  }


  static async withdrawBalance(web3, contractAddress, contract) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.withdraw().encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Withdraw transaction successful"
    };
  }


  static async getContractBaseURI(contract) {
    return await contract.methods.baseURI().call();
  }


  static async setBaseURI(web3, contractAddress, contract, baseURI) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.setBaseURI(baseURI).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Update base URI transaction successful"
    };
  }


  static async getSaleState(contract) {
    return await contract.methods.saleState().call();
  }


  static async setSaleState(web3, contractAddress, contract, saleState) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.setSaleState(saleState).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Set sale state transaction successful"
    };
  }


  static async setProvenance(web3, contractAddress, contract, provenanceHash) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.setProvenanceHash(provenanceHash).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Set provenance hash transaction successful"
    };
  }


  static async reserveTokens(web3, contractAddress, contract, numTokens) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.reserveTokens(numTokens).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Reserve transaction successful"
    };
  }


  static async setRevealTimestamp(web3, contractAddress, contract,
    revealTimestamp) {

    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.setRevealTimestamp(revealTimestamp).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Set reveal timestamp transaction successful"
    };
  }


  static async forceStartingIndex(web3, contractAddress, contract) {
    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        data: contract.methods.forceStartingIndex().encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      message: "Force starting index transaction successful"
    };
  }


  static async mintTokens(web3, contractAddress, contract, tokenEtherPrice,
    numTokens) {

    // handle javascript multiplication issues with floats
    const totalEtherPrice = (tokenEtherPrice * numTokens).toFixed(5);

    const totalWeiPrice = web3.utils.toWei(
      `${totalEtherPrice}`, 'ether');

    const response = await EthereumUtils.sendRequest(web3, {
      method: 'eth_sendTransaction',
      params: [{
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        value: parseInt(totalWeiPrice).toString(16),
        data: contract.methods.mintTokens(numTokens).encodeABI()
      }]
    });

    if (response.error) {
      return {
        status: 1,
        tx: response.tx,
        tokenId: -1,
        message: response.message
      };
    }

    return {
      status: 0,
      tx: response.tx,
      tokenId: web3.utils.hexToNumber(response.receipt.logs[0].topics[3]),
      message: "Mint transaction successful"
    };
  }
}


export default Contract;
