import React from "react";
import { useEffect} from "react";

// page specific configuration
import './RedDirtLabs.css';

// image imports
import logo from './images/rdl_logo.png';
import social_facebook from './images/social/facebook.png';
import social_instagram from './images/social/instagram.png';
import social_twitter from './images/social/twitter.png';
import social_discord from './images/social/discord.png';
import social_opensea from './images/social/opensea.png';


const RedDirtLabs = () => {
  useEffect(() => {
    document.getElementById("favicon").href = "favicon-rdl.png";
    document.title = "Red Dirt Labs";
  });

  return (
    <div className="rdl">
      <div className="rdl-projects">
        <span className="rdl-projects-header">Projects</span>
        <div className="rdl-projects-list">
          <div className="rdl-projects-item">
            <span><a href="slobbercrew">Slobber Crew</a></span>
          </div>
        </div>
      </div>

      <div className="rdl-main">

        <div className="rdl-main-social">
          <a href="https://opensea.io/RedDirtLabs" target="_blank" rel="noreferrer">
            <img className="rdl-main-social-icon" src={social_opensea} alt="OpenSea"/>
          </a>
          <a href="https://discord.gg/CJh7J2TDAe" target="_blank" rel="noreferrer">
            <img className="rdl-main-social-icon" src={social_discord} alt="Discord"/>
          </a>
          <a href="https://www.instagram.com/reddirtlabs/" target="_blank" rel="noreferrer">
            <img className="rdl-main-social-icon" src={social_instagram} alt="Instagram"/>
          </a>
          <a href="https://twitter.com/reddirtlabsllc" target="_blank" rel="noreferrer">
            <img className="rdl-main-social-icon" src={social_twitter} alt="Twitter"/>
          </a>
          <a href="https://www.facebook.com/RedDirtLabs" target="_blank" rel="noreferrer">
            <img className="rdl-main-social-icon" src={social_facebook} alt="Facebook"/>
          </a>
        </div>

        <img className="rdl-main-logo" src={logo} alt="Logo"/>
        <div className="rdl-main-name">Red Dirt Labs</div>
        <div className="rdl-main-description">We are a creative collective of builders, dreamers, and misfits.</div>
        <div className="rdl-main-email">
          <span><a href="mailto:reddirtlabsllc@gmail.com">reddirtlabsllc@gmail.com</a></span>
        </div>

        <div className="rdl-main-projects">
          <span className="rdl-main-projects-header">Projects</span>
          <div className="rdl-main-projects-list">
            <div className="rdl-main-projects-item">
              <span><a href="slobbercrew">Slobber Crew</a></span>
            </div>
          </div>
        </div>

        <div className="rdl-main-footer">
          <span>&copy; 2021 Red Dirt Labs LLC.</span>
        </div>
      </div>
    </div>
  );
};


export default RedDirtLabs;
