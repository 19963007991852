class Ethereum {
  static async connectWallet() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        return {
          status: 0,
          address: accounts[0],
          message: "Connected"
        };

      } catch (error) {
        return {
          status: 2,
          address: "",
          message: error.message,
        };
      }

    } else {
      return {
        status: 1,
        address: "",
        message: "No wallet found"
      };
    }
  }


  static async getConnectedWallet() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        if (accounts.length > 0) {
          return {
            status: 0,
            address: accounts[0],
            message: "Connected"
          };

        } else {
          return {
            status: 1,
            address: "",
            message: "No accounts found"
          };
        }

      } catch (error) {
        return {
          status: 3,
          address: "",
          message: error.message,
        };
      }

    } else {
      return {
        status: 2,
        address: "",
        message: "No wallet found"
      };
    }
  }


  static async sendRequest(web3, requestParams) {
    // define convenience sleep
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    let tx;

    try {
      tx = await window.ethereum.request(requestParams);

      // wait for transaction to complete
      let txReceipt;
      while (!txReceipt) {
        console.log("Waiting for receipt from tx: " + tx);
        await sleep(1000);

        txReceipt = await web3.eth.getTransactionReceipt(tx);
      }

      if (txReceipt.status === false) {
        throw new Error("Transaction failed");
      }
      console.log("Request successful");

      return {
        error: 0,
        tx: tx,
        receipt: txReceipt,
        message: "Request successful"
      };

    } catch (error) {
      console.log("Request error: " + error.message);

      return {
        error: 1,
        tx: tx,
        message: error.message
      };
    }
  }
}


export default Ethereum;
