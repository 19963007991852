import React from 'react';
import { useEffect, useState } from "react";

// common utils
import ContractUtils from "../../../utils/Contract.js"
import EthereumUtils from "../../../utils/Ethereum.js"
import MiscUtils from "../../../utils/Misc.js";

// page specific configuration
import './Admin.css';

const saleStateDescriptions = {
  0: "inactive",
  1: "public",
  2: "pre"
};

let contractHooks;
let walletAddress;


const Admin = (props) => {
  const [contractName, setContractName] = useState();
  const [contractAddress, setContractAddress] = useState();
  const [contractBaseURI, setContractBaseURI] = useState();
  const [saleStateDisplay, setSaleStateDisplay] = useState();
  const [tokenPrice, setTokenPrice] = useState();
  const [tokenSupply, setTokenSupply] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [transactionDisplay, setTransactionDisplay] = useState();


  useEffect(() => {
    async function postRenderInit() {
      contractHooks = await MiscUtils.getContractHooks(
        props.location.search,
        process.env.REACT_APP_SBC_DEFAULT_NETWORK,
        require("../" + props.nftPath + "/contract_info.json"));

      ({ address: walletAddress } = await EthereumUtils.getConnectedWallet());
      login();
    }
    postRenderInit();
  });


  async function login() {
    if (walletAddress === "") {
      ({ address: walletAddress } = await EthereumUtils.connectWallet());
    }

    if (walletAddress === process.env.REACT_APP_ADMIN_ADDRESS.toLowerCase()) {
      // set fixed information that doesn't change
      setContractName(await ContractUtils.getContractName(
        contractHooks.contract));

      setContractAddress(contractHooks.contractInfo.address);

      // update information than can change via admin controls or interaction
      // with the contract
      updateLiveContractInfo();

      document.getElementById("adminControlsRestricted").style.display = "none";
      document.getElementById("adminControls").style.display = "flex";
      document.getElementById("adminDisplay").style.display = "flex";
    }
  }


  async function updateLiveContractInfo() {
    getContractBaseURI();
    getSaleState();
    getTokenPrice();
    getTokenSupply();
    getBalance();
  }


  async function getContractBaseURI() {
    const baseURI = await ContractUtils.getContractBaseURI(
      contractHooks.contract);

    setContractBaseURI(baseURI);
  }


  async function getTokenPrice() {
    const tokenPrice = await ContractUtils.getTokenPrice(
      contractHooks.web3, contractHooks.contract, 'ether');

    setTokenPrice(tokenPrice);
  }


  async function getTokenSupply() {
    const tokenSupply = await ContractUtils.getTokenSupply(
      contractHooks.contract);

    setTokenSupply(tokenSupply);
  }


  async function getBalance() {
    const balance = await ContractUtils.getBalance(
      contractHooks.web3, contractHooks.contractInfo.address, 'ether');

    setContractBalance(`${balance}`);
  }


  async function getSaleState() {
    const saleState = await ContractUtils.getSaleState(contractHooks.contract);
    setSaleStateDisplay(saleStateDescriptions[saleState]);
  }


  async function setSaleState(saleState) {
    const transactionName = `Set ${saleStateDescriptions[saleState]} sale state`;

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.setSaleState(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract, saleState);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function withdrawBalance() {
    const transactionName = "Withdraw balance";

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.withdrawBalance(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function setProvenance() {
    const transactionName = "Set Provenance";
    let provenanceHash = document.getElementById("provenanceHashInput").value;

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.setProvenance(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract,
      provenanceHash);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function reserveTokens() {
    const transactionName = "Reserve tokens";
    let numTokens = document.getElementById("reserveTokensInput").value;

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.reserveTokens(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract, numTokens);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function setRevealTimestamp() {
    const transactionName = "Set Reveal Timestamp";
    let revealTimestamp = document.getElementById("revealTimestampInput").value;

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.setRevealTimestamp(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract,
      revealTimestamp);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function setBaseURI() {
    const transactionName = "Set base URI";
    let baseURIValue = document.getElementById("baseURIInput").value;

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.setBaseURI(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract, baseURIValue);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  async function forceStartingIndex() {
    const transactionName = "Force starting index";

    updateTransactionDisplay("start", transactionName);
    const response = await ContractUtils.forceStartingIndex(contractHooks.web3,
      contractHooks.contractInfo.address, contractHooks.contract);

    if (response.status) {
      updateTransactionDisplay("error", transactionName, response.tx,
        response.message);

      return;
    }
    updateTransactionDisplay("success", transactionName, response.tx);

    updateLiveContractInfo();
  }


  function updateTransactionDisplay(state, name, tx, message) {
    switch(state) {
      case "start":
        setTransactionDisplay(
          <div className="sbc-admin-controls-transaction">
            <label className="sbc-admin-controls-transaction-pending">
              {name} transaction in progress
            </label>
          </div>
        );
        break;

      case "success":
        setTransactionDisplay(
          <div className="sbc-admin-controls-transaction">
            <label className="sbc-admin-controls-transaction-success">
              {name} transaction successful
            </label>
            <a className="sbc-admin-controls-transaction-link"
              href={`${contractHooks.etherscanUrl}/tx/${tx}`}
              target="_blank" rel="noreferrer">Etherscan transaction</a>

            <button className="sbc-admin-controls-transaction-clear"
              onClick={updateTransactionDisplay}>Clear Transaction</button>
          </div>
        );
        break;

      case "error":
        setTransactionDisplay(
          <div className="sbc-admin-controls-transaction">
            <label className="sbc-admin-controls-transaction-error">
              {name} transaction encountered error: {message}
            </label>
            <a className="sbc-admin-controls-transaction-link"
              href={`${contractHooks.etherscanUrl}/tx/${tx}`}
              target="_blank" rel="noreferrer">Etherscan transaction</a>

            <button className="sbc-admin-controls-transaction-clear"
              onClick={updateTransactionDisplay}>Clear Transaction</button>
          </div>
        );
        break;

      default:
        setTransactionDisplay();
    }
  }


  return (
    <div className="sbc-admin">
      <div id="adminControlsRestricted" className="sbc-admin-controls-restricted">
        <label>Access Restricted</label>
      </div>

      <div id="adminControls" className="sbc-admin-controls">
        <div className="sbc-admin-controls-group">
          <label className="sbc-admin-controls-group-header">Sale Management</label>
          <div className="sbc-admin-controls-group-contents">
            <button onClick={() => setSaleState(2)}>
              Enable Pre Sale
            </button>
            <button onClick={() => setSaleState(1)}>
              Enable Public Sale
            </button>
            <button onClick={() => setSaleState(0)}>
              Disable Sale
            </button>
          </div>
        </div>

        <div className="sbc-admin-controls-group">
            <label className="sbc-admin-controls-group-header">
              Funds Management
            </label>
            <div className="sbc-admin-controls-group-contents">
              <button onClick={withdrawBalance}>Withdraw Balance</button>
            </div>
        </div>

        <div className="sbc-admin-controls-group">
            <label className="sbc-admin-controls-group-header">Misc</label>
            <div className="sbc-admin-controls-group-contents">
              <div className="sbc-admin-controls-group-contents-form">
                <form>
                  <label>Number of Reserve Tokens:</label>
                  <input id="reserveTokensInput"/>
                </form>
                <button onClick={reserveTokens}>Reserve Tokens</button>
              </div>
              <div className="sbc-admin-controls-group-contents-form">
                <form>
                  <label>Reveal Timestamp:</label>
                  <input id="revealTimestampInput"/>
                </form>
                <button onClick={setRevealTimestamp}>Set Reveal Timestamp</button>
              </div>
              <div className="sbc-admin-controls-group-contents-form">
                <form>
                  <label>Contract base URI:</label>
                  <input id="baseURIInput"/>
                </form>
                <button onClick={setBaseURI}>Set Base URI</button>
              </div>
            </div>
        </div>

        <div className="sbc-admin-controls-group">
            <label className="sbc-admin-controls-group-header" style={{color: "#FF5500"}}>Danger Zone</label>
            <div className="sbc-admin-controls-group-contents">
              <div className="sbc-admin-controls-group-contents-form">
                <form>
                  <label>Provenance Hash:</label>
                  <input id="provenanceHashInput"/>
                </form>
                <button onClick={setProvenance}>Set Provenance</button>
              </div>

              <button onClick={forceStartingIndex}>Force Starting Index</button>
            </div>
        </div>

        {transactionDisplay}
      </div>

      <div id="adminDisplay" className="sbc-admin-display">
          <div className="sbc-admin-display-header">
            <label>Contract Info</label>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Contract Name:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{contractName}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Contract Address:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{contractAddress}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Contract Base URI:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{contractBaseURI}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Sale State:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{saleStateDisplay}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Token Price:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{tokenPrice}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Token Supply:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{tokenSupply}</label>
            </div>
          </div>
          <div className="sbc-admin-display-item">
            <div className="sbc-admin-display-item-key">
              <label>Balance:</label>
            </div>
            <div className="sbc-admin-display-item-value">
              <label>{contractBalance}</label>
            </div>
          </div>
      </div>
    </div>
  );
}


export default Admin;
