import React from 'react';
import { useEffect, useState } from "react";

// common utils
import ContractUtils from "../../../utils/Contract.js";
import EthereumUtils from "../../../utils/Ethereum.js";
import MiscUtils from "../../../utils/Misc.js";

// page specific configuration
import '../../../common.css';
import './SlobberCrew.css';
import presaleWhitelist from './presale_whitelist.json';

// image imports
import logo from './images/logo.png'
import splash from './images/splash.png';
import social_instagram from './images/social/instagram.png';
import social_twitter from './images/social/twitter.png';
import social_discord from './images/social/discord.png';
import social_opensea from './images/social/opensea.png';
import gallery_1 from './images/gallery/1.png';
import gallery_2 from './images/gallery/2.png';
import gallery_3 from './images/gallery/3.png';
import gallery_4 from './images/gallery/4.png';
import gallery_5 from './images/gallery/5.png';
import gallery_6 from './images/gallery/6.png';
import gallery_7 from './images/gallery/7.png';
import gallery_8 from './images/gallery/8.png';
import gallery_9 from './images/gallery/9.png';
import gallery_10 from './images/gallery/10.png';
import gallery_11 from './images/gallery/11.png';
import gallery_12 from './images/gallery/12.png';
import team_engineer from './images/team/engineer.png';
import team_artist from './images/team/artist.png';
import team_couch from './images/team/couch.png';
import team_sploots from './images/team/sploots.png';
import team_digs from './images/team/digs.png';
import faq_button_icon from './images/arrow.png';
import faq_button_icon_expanded from './images/arrow-down.png'
import menu_icon from './images/menu.svg'

let contractHooks;
let saleState;


const SlobberCrew = (props) => {
  const [walletAddress, setWalletAddress] = useState();
  const [mintStatus, setMintStatus] = useState();
  const [tokenNum, setTokenNum] = useState(1);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [saleStateDescription, setSaleStateDescription] = useState();
  const [presaleWalletStatus, setPresaleWalletStatus] = useState();


  useEffect(() => {
    // set appropriate favicon and title for the page
    document.getElementById("favicon").href = "favicon-sbc.png";
    document.title = "Slobber Crew";

    async function postRenderInit() {
      contractHooks = await MiscUtils.getContractHooks(
        props.location.search,
        process.env.REACT_APP_SBC_DEFAULT_NETWORK,
        require("../slobbercrew/contract_info.json"));

      const { address } = await EthereumUtils.getConnectedWallet();
      setWalletAddress(address);

      setTokenPrice(await ContractUtils.getTokenPrice(
        contractHooks.web3, contractHooks.contract, 'ether'));

      saleState = await ContractUtils.getSaleState(contractHooks.contract);
      if (saleState === '1') {
        setSaleStateDescription("Public Sale Active");
      } else if(saleState === '2') {
        setSaleStateDescription("Pre-sale Active");
      } else {
        setSaleStateDescription("Pre-Sale is active! Public Sale begins Nov. 7th.");
      }
    }
    postRenderInit();

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        }
      });
    }
  });


  async function connectWallet() {
    // load wallet address
    const { status, address, message } = await EthereumUtils.connectWallet();
    if (status) {
      console.error(`Unable to connect wallet: ${message}`);
      return;
    }

    // display wallet address
    setWalletAddress(address);

    // limit mint interface based on sale state
    if (saleState === '0') {
      document.getElementById("sbc-mint-connected-controls").style.display = "none";
    } else if (saleState === '2') {
      if (MiscUtils.isPresaleWallet(presaleWhitelist, walletAddress)) {
        setPresaleWalletStatus(<span style={{ "color": "#85DC00"}}>Approved</span>);
      } else {
        setPresaleWalletStatus(<span style={{ "color": "#FF5500"}}>Restricted</span>);
        document.getElementById("sbc-mint-connected-controls").style.display = "none";
      }
      document.getElementById("sbc-mint-connected-presale").style.display = "flex";
    }

    document.getElementById("sbc-mint-connect").style.display = "none";;
    document.getElementById("sbc-mint-connected").style.display = "flex";
  }


  async function mintTokens() {
    // clear existing result data in the case of someone doing multiple mints
    setMintStatus('');

    // check the token number to make sure it's valid
    if ((tokenNum < 1) || (tokenNum > 40)) {
      alert("Can only mint between 1 and 40 tokens at a time");
      return;
    }

    document.getElementById("sbc-mint-connected-status").style.display = 'flex';
    setMintStatus("Transaction in progress...");

    // trigger the mint transaction
    const { status, tx, message } = await ContractUtils.mintTokens(
      contractHooks.web3, contractHooks.contractInfo.address,
      contractHooks.contract, tokenPrice, tokenNum);

    // update UI based on transaction result (this can take a while)
    switch(status) {
      case 0:
        setMintStatus(
            <span style={{ "color": "#85DC00"}}><b>Success</b>: <a href={`${contractHooks.etherscanUrl}/tx/${tx}`} target="_blank" rel="noreferrer">
                Etherscan transaction</a>
            </span>
        );
        break;

      case 1:
        setMintStatus(
          <span style={{ "color": "#FF5500"}}><b>Error</b>: {message}</span>
        );
        break;

      default:
        setMintStatus(
          <span style={{ "color": "#FF5500"}}><b>Error</b>: Status [{status}], please contact administrator</span>
        );
        break;
    }
  }


  function toggleMenu() {
    const menuElements = document.getElementsByClassName(
      "sbc-menu");

    if (menuElements.length === 0) {
      console.error("Unable to find menu elements");
      return;
    }

    const menuElement = menuElements[0];
    if (menuElement.style.display === "flex") {
      menuElement.style.display = "none";
    } else {
      menuElement.style.display = "flex";
    }
  }


  function toggleFAQItem(faqItemId) {
    const faqItemElement = document.getElementById(faqItemId);

    const faqItemContentElements = faqItemElement.getElementsByClassName(
      "sbc-faq-accordion-item-content");

    if (faqItemContentElements.length === 0) {
      console.error("Unable to find FAQ item content elements");
      return;
    }

    const faqItemButtonIconElements = faqItemElement.getElementsByClassName(
      "sbc-faq-accordion-item-header-icon");

    if (faqItemButtonIconElements.length === 0) {
      console.error("Unable to find FAQ item button icon elements");
      return;
    }

    const faqItemContentElement = faqItemContentElements[0];
    const faqItemButtonIconElement = faqItemButtonIconElements[0];

    if (faqItemContentElement.style.display === "flex") {
      faqItemButtonIconElement.src = faq_button_icon;
      faqItemContentElement.style.display = "none";
    } else {
      faqItemButtonIconElement.src = faq_button_icon_expanded;
      faqItemContentElement.style.display = "flex";
    }
  }


  return (
    <div className="sbc">
      <div className="sbc-nav">
        <div className="sbc-nav-identity">
          <img className="sbc-nav-identity-logo" src={logo} alt="logo"/>
          <div className="sbc-nav-identity-name">Slobber Crew</div>
        </div>
        <div className="sbc-nav-links">
          <div className="sbc-nav-links-item">
            <a href="#sbc-gallery">Gallery</a>
          </div>
          <div className="sbc-nav-links-item">
            <a href="#sbc-roadmap">Roadmap</a>
          </div>
          <div className="sbc-nav-links-item">
            <a href="#sbc-team">Team</a>
          </div>
          <div className="sbc-nav-links-item">
            <a href="#sbc-faq">FAQ</a>
          </div>
        </div>
        <div className="sbc-nav-social">
          <a href="https://opensea.io/collection/slobber-crew" target="_blank" rel="noreferrer">
            <img className="sbc-nav-social-icon" src={social_opensea}
              alt="Discord"/>
          </a>
          <a href="https://discord.gg/UC7fnFrBWu" target="_blank" rel="noreferrer">
            <img className="sbc-nav-social-icon" src={social_discord}
              alt="Discord"/>
          </a>
          <a href="https://www.instagram.com/reddirtlabs/" target="_blank" rel="noreferrer">
            <img className="sbc-nav-social-icon" src={social_instagram}
              alt="Instagram"/>
          </a>
          <a href="https://twitter.com/SlobberCrew" target="_blank" rel="noreferrer">
            <img className="sbc-nav-social-icon" src={social_twitter}
              alt="Twitter"/>
          </a>
        </div>
        <img className="sbc-nav-menu-icon" src={menu_icon} onClick={() => toggleMenu()} alt="menu"/>
      </div>

      <div className="sbc-menu">
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="#sbc-gallery">Gallery</a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="#sbc-roadmap">Roadmap</a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="#sbc-team">Team</a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="#sbc-faq">FAQ</a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="https://discord.gg/UC7fnFrBWu" target="_blank" rel="noreferrer">
            <img className="sbc-menu-item-social-icon" src={social_discord}
              alt="Discord"/>
          </a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="https://www.instagram.com/reddirtlabs/" target="_blank" rel="noreferrer">
            <img className="sbc-menu-item-social-icon" src={social_instagram}
              alt="Instagram"/>
          </a>
        </div>
        <div className="sbc-menu-item" onClick={() => toggleMenu()}>
          <a href="https://twitter.com/SlobberCrew" target="_blank" rel="noreferrer">
            <img className="sbc-menu-item-social-icon" src={social_twitter}
              alt="Twitter"/>
          </a>
        </div>
      </div>

      <div className="sbc-splash">
        <img className="sbc-splash-image" src={splash} alt="splash"/>
      </div>

      <div className="sbc-mint">
        <div id="sbc-mint-connect" className="sbc-mint-connect">
          <span className="sbc-mint-connect-status">{saleStateDescription}</span>
          <button className="sbc-mint-connect-button" onClick={connectWallet}>Connect Wallet</button>
        </div>
        <div id="sbc-mint-connected" className="sbc-mint-connected">
          <div className="sbc-mint-connected-address">
            <span className="sbc-mint-connected-header">Wallet Address</span>
            <span>{walletAddress}</span>
          </div>
          <div id="sbc-mint-connected-presale" className="sbc-mint-connected-presale">
            <span className="sbc-mint-connected-header">Presale Status</span>
            {presaleWalletStatus}
          </div>
          <div id="sbc-mint-connected-controls" className="sbc-mint-connected-controls">
            <span className="sbc-mint-connected-header">Number of tokens</span>
            <span className="sbc-mint-connected-price">({tokenPrice} per NFT)</span>
            <form onSubmit={event => event.preventDefault()}>
              <input type="number" value={tokenNum}
                onChange={event => setTokenNum(event.target.value)} />
            </form>
            <button className="sbc-mint-connected-mint-button" onClick={mintTokens}>Mint</button>
            <div id="sbc-mint-connected-status" className="sbc-mint-connected-status">
              {mintStatus}
            </div>
          </div>
        </div>
      </div>

      <div className="sbc-section-default sbc-description">
        <div className="sbc-description-paragraph">
          <span>The <b>Slobber Crew</b> is a social club for dogs from every walk of life. The only requirements for entry? Jowls! Oh, and a Slobber Crew NFT!</span>
        </div>
        <div className="sbc-description-paragraph">
          <span>Your non-fungible token lives on the Ethereum blockchain and serves as your membership card for the Slobber Crew.</span>
        </div>
        <div className="sbc-description-paragraph">
          <span>There are only 10,000 of these doggos getting ready to romp around on the blockchain! Each one is unique & randomly generated from hand-drawn traits.</span>
        </div>
        <div className="sbc-description-paragraph">
          <span>So get into the dog house and join the <b>Slobber Crew</b>! Minting begins soon.</span>
        </div>
      </div>

      <div id="sbc-gallery" className="sbc-section-default sbc-gallery">
        <div className="sbc-section-header">Gallery</div>
        <div className="sbc-gallery-examples">
          <img className="sbc-gallery-examples-image" src={gallery_1} alt="example-1"/>
          <img className="sbc-gallery-examples-image" src={gallery_2} alt="example-2"/>
          <img className="sbc-gallery-examples-image" src={gallery_3} alt="example-3"/>
          <img className="sbc-gallery-examples-image" src={gallery_4} alt="example-4"/>
          <img className="sbc-gallery-examples-image" src={gallery_5} alt="example-5"/>
          <img className="sbc-gallery-examples-image" src={gallery_6} alt="example-6"/>
          <img className="sbc-gallery-examples-image" src={gallery_7} alt="example-7"/>
          <img className="sbc-gallery-examples-image" src={gallery_8} alt="example-8"/>
          <img className="sbc-gallery-examples-image" src={gallery_9} alt="example-9"/>
          <img className="sbc-gallery-examples-image" src={gallery_10} alt="example-10"/>
          <img className="sbc-gallery-examples-image" src={gallery_11} alt="example-11"/>
          <img className="sbc-gallery-examples-image" src={gallery_12} alt="example-12"/>
        </div>
      </div>

      <div id="sbc-roadmap" className="sbc-section-default sbc-roadmap">
        <div className="sbc-section-header">Roadmap</div>
        <div className="sbc-roadmap-milestone">
          <div className="sbc-roadmap-milestone-name">
            <span>25%</span>
          </div>
          <div className="sbc-roadmap-milestone-description">
            <span>Unlock VIP area in Discord for giveaways & social events exclusive to Slobber Crew holders.</span>
            <span>Airdrop 10 Slobber Crew NFTs to 10 random Slobber Crew holders (1 NFT each).</span>
          </div>
        </div>
        <div className="sbc-roadmap-milestone">
          <div className="sbc-roadmap-milestone-name">
            <span>50%</span>
          </div>
          <div className="sbc-roadmap-milestone-description">
            <span>Build member exclusive merchandise store.</span>
          </div>
        </div>
        <div className="sbc-roadmap-milestone">
          <div className="sbc-roadmap-milestone-name">
            <span>75%</span>
          </div>
          <div className="sbc-roadmap-milestone-description">
            <span>Make a donation to a charity benefiting animals. Charity will be selected by a Slobber Crew holder community vote.</span>
          </div>
        </div>
        <div className="sbc-roadmap-milestone">
          <div className="sbc-roadmap-milestone-name">
            <span>100%</span>
          </div>
          <div className="sbc-roadmap-milestone-description">
            <span>Add Rarity tools.</span>
            <span>List Slobber Crew on OpenSea for trading.</span>
            <span>Develop companion / special edition NFT collections exclusive to Slobber Crew holders.</span>
          </div>
        </div>
      </div>

      <div id="sbc-team" className="sbc-section-default sbc-team">
        <div className="sbc-section-header">Team</div>
        <div className="sbc-team-members">
          <div className="sbc-team-member">
            <img className="sbc-team-members-member-image" src={team_engineer} alt="engineer"/>
            <div className="sbc-team-members-member-name">DemJowlsTho</div>
            <div className="sbc-team-members-member-description">Tech Guru & Engineer</div>
          </div>
          <div className="sbc-team-members-member">
            <img className="sbc-team-members-member-image" src={team_artist} alt="artist"/>
            <div className="sbc-team-members-member-name">DaneWrangler </div>
            <div className="sbc-team-members-member-description">Doodler & Designer</div>
          </div>
          <div className="sbc-team-members-member">
            <img className="sbc-team-members-member-image" src={team_couch} alt="finance"/>
            <div className="sbc-team-members-member-name">CouchIsLyfe</div>
            <div className="sbc-team-members-member-description">Finance</div>
          </div>
          <div className="sbc-team-members-member">
            <img className="sbc-team-members-member-image" src={team_sploots} alt="marketing"/>
            <div className="sbc-team-members-member-name">SirSplootsAlot</div>
            <div className="sbc-team-members-member-description">Marketing</div>
          </div>
          <div className="sbc-team-members-member">
            <img className="sbc-team-members-member-image" src={team_digs} alt="community"/>
            <div className="sbc-team-members-member-name">MuddyPaws</div>
            <div className="sbc-team-members-member-description">Community Engagement</div>
          </div>
        </div>
      </div>

      <div id="sbc-faq" className="sbc-section-default sbc-faq">
        <div className="sbc-section-header">FAQ</div>
        <div className="sbc-faq-accordion">
          <div id="sbc-faq-item-1" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#5E5F5F"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-1")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>What is Slobber Crew?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
            Slobber Crew is a collection of 10,000 unique, randomly generated jowly dog collectibles living
            on the Ethereum blockchain as ERC-721 tokens. These puppers can't wait to come home with you!
            </div>
          </div>
          <div id="sbc-faq-item-2" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#9B9B9B"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-2")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>How can I purchase a Slobber Crew NFT?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              Slobber Crew will be available to mint on our website in early November. Pre-sale will be on
              Saturday, November 6th & public sale will open on Sunday, November 7th.
            </div>
          </div>
          <div id="sbc-faq-item-3" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#5E5F5F"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-3")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>How much will a Slobber Crew token cost?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              0.05 ETH + gas fees.
            </div>
          </div>
          <div id="sbc-faq-item-4" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#9B9B9B"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-4")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>How many can I mint?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              Up to 40 per transaction.
            </div>
          </div>
          <div id="sbc-faq-item-5" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#5E5F5F"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-5")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>Where will the sale take place?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              Initial minting will take place here on our website. After the collection sells out, secondary
              sales will be available on opensea.io
            </div>
          </div>
          <div id="sbc-faq-item-6" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#9B9B9B"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-6")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>Will there be a pre-sale?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              Yes. A pre-sale white list will be created based upon community engagement in our Discord server
              and other social media channels. This private sale will be available on our website for 24 hours
              before the public sale drops.
            </div>
          </div>
          <div id="sbc-faq-item-7" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#5E5F5F"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-7")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>What’s the contract address?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              <span>Verified ERC-721 contract can be found <a href="https://etherscan.io/address/0x2966D6742D91D75BCF37E003f8451c9E0EAde48c" style={{ "color": "inherit" }} target="_blank" rel="noreferrer">here</a>.</span>
            </div>
          </div>
          <div id="sbc-faq-item-8" className="sbc-faq-accordion-item" style={{ "backgroundColor": "#9B9B9B"}}>
            <div className="sbc-faq-accordion-item-header" onClick={() => toggleFAQItem("sbc-faq-item-8")}>
              <img className="sbc-faq-accordion-item-header-icon" src={faq_button_icon} alt="faq-icon"/>
              <div>Where can I find provenance information for Slobber Crew?</div>
            </div>
            <div className="sbc-faq-accordion-item-content">
              <span>Provenance information can be found <a href="/slobbercrew/provenance" style={{ "color": "inherit" }} target="_blank" rel="noreferrer">here</a>.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="sbc-footer">
        <span>&copy; 2021 Slobber Crew by <a href="/">Red Dirt Labs LLC.</a></span>
      </div>

    </div>
  );
}


export default SlobberCrew;
