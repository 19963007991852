import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import RedDirtLabs from "./pages/rdl/RedDirtLabs";
import Admin from "./pages/nfts/admin/Admin";
import SlobberCrew from "./pages/nfts/slobbercrew/SlobberCrew";
import SlobberCrewProvenance from "./pages/nfts/slobbercrew/Provenance";


ReactDOM.render(
  <React.StrictMode>
  <Router>
    <Route exact path="/" component={RedDirtLabs} />
    <Route exact path="/slobbercrew" component={SlobberCrew} />
    <Route exact path="/slobbercrew/admin"
      render={(props) => <Admin {...props} nftPath="slobbercrew" />}
    />
    <Route exact path="/slobbercrew/provenance"
      component={SlobberCrewProvenance} />
  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
