import queryString from 'query-string';

// common utils
import ContractUtils from "./Contract.js";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");


class Misc {
  static async getContractHooks(networkQueryString, defaultNetwork, contractInfoJSON) {
    const queryParams = queryString.parse(networkQueryString);

    // determine network from query string
    let network = defaultNetwork;
    if (queryParams.network) {
      network = queryParams.network;
    }

    // make sure the contract is configured for the network
    if (contractInfoJSON[network] == null) {
      throw new Error('No contract info found for network: ' + network);
    }

    // make sure there is an Alchemy key for the network and set etherscan URL
    let web3;
    let etherscanUrl;

    switch(network) {
      case "mainnet":
        web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_KEY_MAINNET);
        etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL_MAINNET;

        break;

      case "ropsten":
        web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_KEY_ROPSTEN);
        etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL_ROPSTEN;

        break;

      default:
        throw new Error('No Alchemy key found for network: ' + network);
    }

    const contractInfo = contractInfoJSON[network];
    const contract = await ContractUtils.getContract(
      web3, contractInfo.abi, contractInfo.address);

    const contractHooks = {
      web3: web3,
      contractInfo: contractInfo,
      contract: contract,
      etherscanUrl: etherscanUrl
    };

    return contractHooks;
  }

0xeE2e24cAa7a9878a3ad2D68B72eA768e49B2A9eD
  static isPresaleWallet(whitelistJSON, walletAddress) {
    for (const [name, addr] of Object.entries(whitelistJSON.addresses)) {
      if (addr.toLowerCase() === walletAddress) {
        console.log("Pre-sale authorization found for: " + name);
        return true;
      }
    }

    return false;
  }
}


export default Misc;
